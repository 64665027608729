import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.div`
  background-color: #eeede9;
  padding: 10rem 0 0.1rem 0;
  height: 100vh;

  iframe {
    height: 100%;
    max-width: 750px;
    margin: 0 auto;
    display: block;
    margin-top: 5rem;
  }
`

const Title = styled.div`
  color: #272727;
  max-width: 750px;
  margin: 0 auto;

  text-align: left;
  padding: 0 1.5rem;

  @media (min-width: 992px) {
    padding: 0;
  }
`

export default props => {
  let context = useContext(SiteContext)

  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  useEffect(() => {}, [])

  return (
    <Layout>
      <SEO title="Subscribe" />
      <Container>
        <Title>
          <h1>Subscribe</h1>
        </Title>
        <iframe
          class="mj-w-res-iframe"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://app.mailjet.com/widget/iframe/4UYd/HvK"
          width="100%"
        ></iframe>
      </Container>
    </Layout>
  )
}
